.notfound {
    text-align: center;
    font-family: "Inter", sans-serif;
    padding: 20px;
    margin-bottom: 40px;
    margin-top: 50px;
    margin-right: 400px;
    margin-left: 400px;
}

.notfound-content {
    margin-top: 40px;
    border-radius: 16px;
    color: #ffffff;
    background-color: #1e293b;
    max-width: 1800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1e293b;
    padding-top: 20px;
    padding-bottom: 50px;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50), inset 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.notfound-content h2 {
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 10px;
}

.notfound-content p {
    color: #ffffff;
    font-size: 20px;
    margin: 10px 0;
}

.button-notfound {
    background-color: #0ea3e5;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    transition: transform 0.3s ease-in-out;
}

.button-notfound:hover {
    transform: scale(1.1);
    background-color: #0ea4e7;
}


