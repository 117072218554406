.navbar {
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    padding: 20px;
    background-color: #1e293b;
    overflow: hidden;
    clip-path: polygon(100% 0%, 0% 0% , 0.00% 97.81%, 1.00% 97.31%, 2.00% 96.76%, 3.00% 96.17%, 4.00% 95.55%, 5.00% 94.91%, 6.00% 94.25%, 7.00% 93.57%, 8.00% 92.90%, 9.00% 92.22%, 10.00% 91.57%, 11.00% 90.93%, 12.00% 90.32%, 13.00% 89.74%, 14.00% 89.21%, 15.00% 88.72%, 16.00% 88.28%, 17.00% 87.90%, 18.00% 87.59%, 19.00% 87.34%, 20.00% 87.15%, 21.00% 87.04%, 22.00% 87.00%, 23.00% 87.03%, 24.00% 87.14%, 25.00% 87.31%, 26.00% 87.55%, 27.00% 87.86%, 28.00% 88.23%, 29.00% 88.66%, 30.00% 89.15%, 31.00% 89.68%, 32.00% 90.25%, 33.00% 90.85%, 34.00% 91.49%, 35.00% 92.15%, 36.00% 92.82%, 37.00% 93.49%, 38.00% 94.17%, 39.00% 94.83%, 40.00% 95.48%, 41.00% 96.10%, 42.00% 96.69%, 43.00% 97.25%, 44.00% 97.76%, 45.00% 98.21%, 46.00% 98.62%, 47.00% 98.96%, 48.00% 99.24%, 49.00% 99.45%, 50.00% 99.59%, 51.00% 99.66%, 52.00% 99.66%, 53.00% 99.58%, 54.00% 99.44%, 55.00% 99.22%, 56.00% 98.94%, 57.00% 98.59%, 58.00% 98.19%, 59.00% 97.73%, 60.00% 97.22%, 61.00% 96.66%, 62.00% 96.07%, 63.00% 95.44%, 64.00% 94.79%, 65.00% 94.13%, 66.00% 93.45%, 67.00% 92.78%, 68.00% 92.11%, 69.00% 91.45%, 70.00% 90.82%, 71.00% 90.21%, 72.00% 89.64%, 73.00% 89.12%, 74.00% 88.64%, 75.00% 88.21%, 76.00% 87.84%, 77.00% 87.54%, 78.00% 87.30%, 79.00% 87.13%, 80.00% 87.03%, 81.00% 87.00%, 82.00% 87.05%, 83.00% 87.16%, 84.00% 87.35%, 85.00% 87.60%, 86.00% 87.92%, 87.00% 88.30%, 88.00% 88.74%, 89.00% 89.24%, 90.00% 89.77%, 91.00% 90.35%, 92.00% 90.96%, 93.00% 91.60%, 94.00% 92.26%, 95.00% 92.94%, 96.00% 93.61%, 97.00% 94.28%, 98.00% 94.95%, 99.00% 95.59%, 100.00% 96.21%);
}


.navbar-logo {
    color: white;
    font-size: 24px;
    font-weight: bold;
    flex: 1;
    max-width: 100px;
}

.navbar-links {
    display: flex;
    gap: 20px;
    max-width: 1900px;
    flex-wrap: wrap;
    margin-left: auto;
}

.nav-item {
    color: #fdfdfd;
    font-weight: 800;
    font-size: 18px;
    text-decoration: none;
    padding: 10px;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.nav-item:hover {
    transform: scale(1.05);
}

.nav-item::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: #93a2b7;
    transition: width 0.3s ease, left 0.3s ease;
}

.nav-item:hover::after, .active::after {
    width: 100%;
    height: 3px;
    border-radius: 8px;
    left: 0;
}

.special-button {
    background-color: #0ea4e7;
    color: white;
    border-radius: 8px;
    padding: 12px 20px;
    font-weight: 900;
    font-size: 15px;
    text-decoration: none;
    transition: transform 0.3s ease-in-out;
}

.special-button:hover {
    transform: scale(1.1);
}

.special-button::after {
    display: none;
}

.logo-image {
    max-width: 100px;
    height: auto;
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .navbar-links {
        margin-left: 0;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 30px;
    }

    .nav-item {
        margin: 0;
        margin-bottom: 10px;
    }
}
