.gallery-container {
    text-align: center;
    font-family: "Inter", sans-serif;
    margin-bottom: 50px;
    margin-top: 40px;
}

.gallery-title {
    font-size: 34px;
    color: #32475e;
    font-weight: 800;
    text-shadow: rgba(30, 41, 59, 0.25) 3px 3px 2px;
}

.gallery-description {
    color: #445266;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 800;
    margin-bottom: 20px;
    text-shadow: rgba(30, 41, 59, 0.25) 3px 3px 2px;
}

.gallery-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    max-width: 1800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1e293b;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50), inset 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.gallery-image {
    max-width: calc(33.33% - 60px);
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50), inset 0px 4px 4px rgba(0, 0, 0, 0.15);
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.gallery-image:hover {
    transform: scale(1.05);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.4);
}

@media (max-width: 768px) {
    .gallery-image {
        max-width: calc(50% - 10px);
    }
}

@media (max-width: 480px) {
    .gallery-image {
        max-width: 100%;
    }
}
