.contact-page {
    display: flex;
    flex-direction: column;
    font-family: "Inter", sans-serif;
    align-items: center;
    padding: 20px;
}

.contact-title {
    font-size: 36px;
    color: #32475e;
    margin-top: 40px;
    font-weight: 800;
    text-shadow: rgba(30, 41, 59, 0.25) 3px 3px 2px;
}


.contact-description {
    color: #445266;
    font-size: 20px;
    margin-top: -10px;
    font-weight: 800;
    margin-bottom: 20px;
    text-shadow: rgba(30, 41, 59, 0.25) 3px 3px 2px;
}

.contact-container {
    width: 100%;
    max-width: 1000px;
    background-color: #1e293b;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50), inset 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.contact-form {
    max-width: 100%;
    width: 100%;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.form-group {
    flex: 1 1 45%; /* Adjust width as needed */
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.form-group label {
    color: #ffffff;
    font-weight: bold;
    display: block;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.70);
    background-color: #d1d7e2;
    font-size: 1rem;
}

textarea {
    resize: vertical;
    min-height: 150px;
}

.white-text {
    color: white;
    font-weight: bold;
}

.char-warning {
    color: red;
    margin-top: 5px;
}

.button-contact {
    background-color: #0ea3e5;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    transition: transform 0.3s ease-in-out;
}

.button-contact:hover {
    transform: scale(1.1);
    background-color: #0ea4e7;
}

input:focus,
textarea:focus {
    border-color: #0ea4e7;
    outline: none;
}
