.linkconfirmation-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: #1e293b;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    color: #ffffff;
    max-width: 500px;
    width: 100%;
    z-index: 1000; /* Ensure modal is on top */
    box-sizing: border-box;
}

.linkconfirmation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999; /* Ensure overlay is on top but below modal */
}

.linkconfirmation-content {
    text-align: center;
}

.button-not {
    background-color: #e50e0e;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    margin: 20px;
    transition: transform 0.3s ease-in-out;
}

.button-not:hover {
    transform: scale(1.1);
    background-color: #e70e0e;
}

.button-yes {
    background-color: #00ff6f;
    color: white;
    margin: 20px;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    transition: transform 0.3s ease-in-out;
}

.button-yes:hover {
    transform: scale(1.1);
    background-color: #00ff6f;
}