.home {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    font-family: "Inter", sans-serif;
    position: relative;
    z-index: -1;
}

.home::before {
    content: '';
    height: 100%;
    width: 100%;
    position: fixed; /* Changed to fixed */
    top: 0;
    left: 0;
    background-image: url('../assets/szkic.png');
    background-size: cover; /* Ensure the background covers the entire element */
    background-position: center;
    filter: blur(4px);
    z-index: -1;
}

.content {
    background-color: rgba(30, 41, 59, 0.90);
    padding: 20px;
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0 20px;
    max-width: 800px;
    margin-bottom: 70px;
    margin-top: 70px;
    width: 100%;
}

.home h1 {
    font-size: 36px;
    color: #ffffff;
    font-weight: 800;
}

.home p {
    color: #ffffff;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 200;
    margin-bottom: 20px;
}
