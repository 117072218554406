.uslugi-container {
    text-align: center;
    font-family: "Inter", sans-serif;
    padding: 20px;
    margin-bottom: 30px;
}

.uslugi-title {
    margin-top: 40px;
    font-size: 36px;
    color: #32475e;
    text-shadow: rgba(30, 41, 59, 0.25) 3px 3px 2px;
    font-weight: 800;
}

.uslugi-description {
    color: #445266;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 800;
    text-shadow: rgba(30, 41, 59, 0.25) 3px 3px 2px;
    margin-bottom: 20px;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    max-width: 1800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1e293b;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50), inset 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.card {
    background-color: #f9f9f9;
    border-radius: 8px;
    width: 540px;
    height: auto;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.3s ease;
}

.card-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    transition: opacity 0.3s ease;
    text-align: center;
}


.card-title, .card-description {
    display: none;
}

.card:hover .card-image {
    filter: blur(5px);
}

.card:hover .card-content {
    opacity: 1;
    background: rgba(209, 215, 226, 0.51);
}

.card:hover .card-title, .card:hover .card-description {
    display: block;
}

.card-title {
    font-size: 24px;
    padding-top: 15px;
    color: #32465d;
    margin: -10px;
}

.card-description {
    font-size: 18px;
    font-weight: 700;
    color: #435165;
}


.card-hover {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card-hover:hover {
    transform: scale(1.05);
    z-index: 1;
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.3);
}


