
body{
    background-color: #e0e6ee;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.footer {
    background-color: #d3d9e4;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "Inter", sans-serif;
    margin-top: auto;
    width: 100%;
    color: #334961;
    text-align: center;
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 8.08%, 1.00% 7.92%, 2.00% 7.75%, 3.00% 7.56%, 4.00% 7.37%, 5.00% 7.16%, 6.00% 6.95%, 7.00% 6.74%, 8.00% 6.53%, 9.00% 6.32%, 10.00% 6.11%, 11.00% 5.91%, 12.00% 5.71%, 13.00% 5.53%, 14.00% 5.36%, 15.00% 5.21%, 16.00% 5.07%, 17.00% 4.95%, 18.00% 4.85%, 19.00% 4.77%, 20.00% 4.71%, 21.00% 4.68%, 22.00% 4.67%, 23.00% 4.68%, 24.00% 4.71%, 25.00% 4.76%, 26.00% 4.84%, 27.00% 4.94%, 28.00% 5.06%, 29.00% 5.19%, 30.00% 5.34%, 31.00% 5.51%, 32.00% 5.69%, 33.00% 5.88%, 34.00% 6.08%, 35.00% 6.29%, 36.00% 6.50%, 37.00% 6.72%, 38.00% 6.93%, 39.00% 7.14%, 40.00% 7.34%, 41.00% 7.54%, 42.00% 7.73%, 43.00% 7.90%, 44.00% 8.06%, 45.00% 8.21%, 46.00% 8.33%, 47.00% 8.44%, 48.00% 8.53%, 49.00% 8.60%, 50.00% 8.64%, 51.00% 8.66%, 52.00% 8.66%, 53.00% 8.64%, 54.00% 8.59%, 55.00% 8.53%, 56.00% 8.44%, 57.00% 8.33%, 58.00% 8.20%, 59.00% 8.05%, 60.00% 7.89%, 61.00% 7.72%, 62.00% 7.53%, 63.00% 7.33%, 64.00% 7.13%, 65.00% 6.92%, 66.00% 6.70%, 67.00% 6.49%, 68.00% 6.28%, 69.00% 6.07%, 70.00% 5.87%, 71.00% 5.68%, 72.00% 5.50%, 73.00% 5.33%, 74.00% 5.18%, 75.00% 5.05%, 76.00% 4.93%, 77.00% 4.84%, 78.00% 4.76%, 79.00% 4.71%, 80.00% 4.68%, 81.00% 4.67%, 82.00% 4.68%, 83.00% 4.72%, 84.00% 4.78%, 85.00% 4.86%, 86.00% 4.96%, 87.00% 5.08%, 88.00% 5.22%, 89.00% 5.37%, 90.00% 5.54%, 91.00% 5.73%, 92.00% 5.92%, 93.00% 6.12%, 94.00% 6.33%, 95.00% 6.54%, 96.00% 6.75%, 97.00% 6.97%, 98.00% 7.18%, 99.00% 7.38%, 100.00% 7.57%);
}

.footer-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 1900px;
    color: #334961;
    flex-wrap: wrap;
    text-align: center;
}

.footer-info,
.footer-nav,
.footer-social {
    flex: 1;
    margin: 20px;
}

.footer-nav ul,
.footer-social ul {
    list-style-type: none;
    padding: 0;
}

.footer-nav ul li,
.footer-social ul li {
    margin-bottom: 5px;
}

.footer-nav h3,
.footer-social h3 {
    font-weight: 800;
    font-size: 20px;
    color: #334961;
    margin-bottom: 10px;
}

.footer-info,
.footer-nav a,
.footer-social a {
    color: #465468;
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
}

.footer-nav a:hover,
.footer-social a:hover {
    text-decoration: underline;
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
    width: 100%;
    font-weight: 800;
    font-size: 18px;
    color: #465468;
    border-top: 3px solid #c9d3df;
    padding-top: 10px;
}

.footer-bottom p {
    margin: 5px 0;
    font-size: 14px;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }
}


.footer-link {
    color: inherit;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}
