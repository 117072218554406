.confirmation-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: #1e293b;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    color: #ffffff;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
}

.confirmation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.confirmation-content {
    text-align: center;
}

.button-contact {
    background-color: #0ea3e5;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    transition: transform 0.3s ease-in-out;
}

.button-contact:hover {
    transform: scale(1.1);
    background-color: #0ea4e7;
}
